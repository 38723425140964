import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import '../partners.scss';
import { Row, Col, Button } from 'react-bootstrap';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';
import { faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '../../modules/common/components/box';

const NIKKKEWatchLivePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page partners-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke">Nikke</Link>
        </li>
        <li className="divider">/</li>
        <li>Watch our partners</li>
      </ul>
      <div className="top-partner-intro nikke">
        <Box>
          <Row>
            <Col>
              <div className="inner">
                <h1>Watch our NIKKE Content Creator partners!</h1>
                <p>
                  Are you looking for good videos or streams featuring NIKKE?
                  Check out our partners!
                </p>
              </div>
            </Col>
            <Col className="with-image">
              <StaticImage
                src="../../images/nikke/partner_img_nikke.png"
                alt="Partner"
              />
            </Col>
          </Row>
        </Box>
      </div>
      <div className="page-content">
        <SectionHeader title="Our Partners" />
        <Row xs={1} xxl={2} className="partner-row">
          <Col>
            <Box>
              <div className="top red">
                <div className="partner-image">
                  <StaticImage
                    src="../../images/partner_tim.png"
                    alt="TimaeuSS"
                  />
                </div>
                <h2>TimaeuSS</h2>
              </div>
              <div className="middle">
                <h6>About TimaeuSS</h6>
                <p>
                  TimaeuSS is a Dr/Papa/VTuber that can be found trying out new
                  mobile games on release. He has since settled on making NIKKE
                  and Star Rail his main games as a Modernia + Kafka Simp!
                </p>
                <h6>Check TimaeuSS content</h6>
                <div className="links">
                  <Button
                    variant="primary"
                    href="https://www.youtube.com/@TimaeuSS/featured"
                    className="link-youtube"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} width="18" /> YouTube
                  </Button>
                  <Button
                    variant="primary"
                    href="https://www.twitch.tv/timaeuss"
                    className="link-twitch"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitch} width="18" /> Twitch
                  </Button>
                </div>
                <h6>Featured videos</h6>
                <p>Check TimaeuSS featured videos below!</p>
              </div>
            </Box>
          </Col>
        </Row>
        <SectionHeader title="TimaeuSS featured videos" />
        <Row xs={1} xxl={3}>
          <Col>
            <YoutubeEmbed embedId="QVq2E7wd8No" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="0ComaBfI7MQ" />
          </Col>
          <Col>
            <YoutubeEmbed embedId="qn5kQi-R_Dc" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default NIKKKEWatchLivePage;

export const Head: React.FC = () => (
  <Seo
    title="Watch our partners | NIKKE | Prydwen Institute"
    description="Check out our NIKKE Content Creator partners!"
    game="nikke"
  />
);
